import {Context, Router} from "@profiscience/knockout-contrib-router";
import {Evaluation, EvaluationDto, IdeaDto} from "../../api/generated";
import {evaluationApi, ideaApi} from "../../api/api-wrapper";
import {autobind, observable, unwrap} from "knockout-decorators";
import {ideaAttachmentsRegex} from "../ideas/ideaUtils";
import {App} from "../../app";
import globalState from "../../global-state"
import {postbox} from "../../components/util/postbox";
import * as ko from "knockout";
import {UserSearchForm} from "../../forms/user-search-form";
import {bonus, evaluationDeadline} from "./evaluationUtils";
import {FileUploadViewModel} from "../../utils/FileUploadViewModel";
import "../../components/elements/idea/evaluation-idea"
import {FilesData} from "../editor/_common";
import {createConfirmModal} from "../../components/elements/modal/modal";
import i18nextko from "../../bindings/i18nko";
import ImplementEnum = Evaluation.ImplementEnum;
import PostponeReasonEnum = Evaluation.PostponeReasonEnum;


class ViewModelContext extends Context {
    evaluation: EvaluationDto;
    idea: IdeaDto;
}

/**
 * Form to assign the evaluation to a new user.
 */
class HandoverForm extends UserSearchForm {

    /**
     * The comment.
     */
    public comment: KnockoutObservable<string>;

    /**
     * Constructor.
     */
    constructor() {
        super();
        this.comment = ko.observable("");
    }
}

class ViewModel extends FileUploadViewModel {

    /**
     * The evaluation.
     */
    @observable({deep: true, expose: true})
    public evaluation: EvaluationDto;

    /**
     * The idea.
     * The evaluation contains a IdeaSlim object only. To display the description, advantage and audience teh idea has
     * to be loaded separately.
     */
    public idea: IdeaDto;

    /**
     * Files data for the implementation description.
     * Stores files for the implementation description which is an additional list of files
     * than the one from the FileUploadViewModel.
     */
    public implementationFilesData: KnockoutObservable<FilesData>;

    /**
     * The form to handover an evaluation to another user.
     */
    public handoverForm: HandoverForm;

    /**
     * Validate as required only if the evaluation implement property equals to Implement.
     */
    public validateImplement: KnockoutComputed<boolean>;

    /**
     * Validate as required only if the the evaluation is active and evaluated
     */
    public validateConditional: KnockoutComputed<boolean>;

    /**
     * The bonus according to the score of all criterias
     */
    public bonus: KnockoutComputed<number>;

    /**
     * Switch to accept expert user is only visible if the user has not been accepted so far.
     */
    public expertApprovedVisible: boolean;

    /**
     * Is the handover comment visible?
     */
    public handoverCommentVisible: boolean;

    public submittedVisible: boolean;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluation = Object.assign({}, this.createEvaluation(), ctx.evaluation);
        this.idea = ctx.idea;
        this.implementationFilesData = this.createFilesData();
        this.handoverForm = new HandoverForm();
        this.validateConditional = ko.computed(() => this.evaluation.evaluated == true);
        this.validateImplement = ko.computed(() => this.evaluation.implement === ImplementEnum.Implement);
        this.bonus = bonus(this.evaluation);

        unwrap(this.evaluation, "implement").extend({
            required: {
                onlyIf: this.validateConditional
            }

        });
        unwrap(this.evaluation, "implementationDate").extend({
            required: {
                onlyIf: this.validateImplement
            }

        });
        unwrap(this.evaluation, "description").extend({
            required: {
                onlyIf: this.validateConditional
            },
            maxlength: 65535

        });
        this.expertApprovedVisible = this.evaluation.expertApproved !== true;
        this.handoverCommentVisible = this.evaluation.handoverComment && this.evaluation.handoverComment.trim().length > 0;
        this.submittedVisible = this.evaluation.submitted !== true;

        console.debug("Edit evaluation: ", this.evaluation);
    }

    /**
     * Delete the evaluation with a confirm dialog.
     */
    @autobind
    public deleteEvaluationConfirmation() {
        return createConfirmModal(
            i18nextko.t("evaluation.delete.confirmText"), i18nextko.t("evaluation.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return evaluationApi.deleteEvaluation(this.evaluation.id).then(value => {
                    globalState.loading(false);
                    postbox.addInfo("evaluation.success.delete");
                    return Router.update(`/idee/${this.evaluation.idea.id}`, {
                        push: false,
                        force: true
                    });
                }).catch(reason => {
                    globalState.loading(false);
                    postbox.addError("evaluation.error.delete");
                    return Promise.reject(reason);
                })
            }).catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

    /**
     * Get the options for the evaluation implement property
     */
    public implementOptions() {
        return App.enumOptions(ImplementEnum, "evaluation.implement.");
    }

    /**
     * Get the options for the postpone reason.
     */
    public postponeReasonOptions() {
        return App.enumOptions(PostponeReasonEnum, "evaluation.postponeReason.");
    }

    /**
     * Get the evaluation deadline.
     */
    @autobind
    public evaluationDeadline(): KnockoutComputed<Date> {
        return evaluationDeadline(this.evaluation);
    }

    /**
     * Save the evaluation.
     */
    @autobind
    save() {
        console.debug("saving evaluation", this.evaluation);
        const errors = ko.validation.group(this.evaluation);
        if (errors().length > 0) {
            console.debug("errors", errors());
            errors.showAllMessages();
            postbox.addError('validation.failed');
        } else {
            globalState.loading(true);
            return Promise.all([
                this.filesPromise(ideaAttachmentsRegex)
                    .then(uploads =>
                        this.evaluation.uploads = uploads),
                this.filesPromise(ideaAttachmentsRegex, this.implementationFilesData)
                    .then(uploads =>
                        this.evaluation.implementationUploads = uploads)
            ])
                .then(value =>
                    evaluationApi.putEvaluation(this.evaluation.id, this.evaluation, globalState.sendMail()).then(value => {
                        globalState.loading(false);
                        postbox.addInfo("evaluation.success.save");
                        return Router.update(`/idee/${this.evaluation.idea.id}`, {
                            push: true,
                            force: false
                        });
                    }).catch(reason => {
                        globalState.loading(false);
                        postbox.addError("evaluation.error.save");
                        console.error(reason);
                    })
                )
                .catch(err =>
                    console.error(err))
                .finally(() =>
                    globalState.loading(false));
        }
    }

    @autobind
    public load_summary() {
        globalState.loading(true);
        return ideaApi.getIdeaEvaluationsSummary(this.idea.id).then(summaryEvaluation => {
            this.evaluation.description = summaryEvaluation.description;

            // copy score and comment from summary to current evaluation criterias
            summaryEvaluation.criteriaList.forEach(summaryCriteria => {
                if(summaryCriteria.comment || summaryCriteria.score) {
                    const targetCriteria = this.evaluation.criteriaList.find(criteria => criteria.type == summaryCriteria.type);
                    if(targetCriteria) {
                        targetCriteria.comment = summaryCriteria.comment || targetCriteria.comment;
                        targetCriteria.score = summaryCriteria.score || targetCriteria.score;
                    }
                }
            });

            return Promise.resolve();
        }).catch(reason => {
            postbox.addError("evaluation.error.summary");
            console.error(reason);
        }).finally(() =>
            globalState.loading(false));
    }

    /**
     * Cancel the evaluation
     */
    @autobind
    cancel() {
        return Router.update(`/idee/${this.evaluation.idea.id}`, {
            push: true,
            force: false
        });
    }

    /**
     * Create an evaluation with all the properties which can be edited in this view.
     */
    private createEvaluation() {
        return {
            description: null,
            implement: null,
            implementationDate: null,
            implementationDescription: null,
            score: null,
            bonus: null,
            submitted: false,
            revise: false,
            evaluated: false,
            postponeUntil: null,
            postponeReason: null,
            active: false,
            criteriaList: [],
            adminDescription: null,
            postponeAccepted: null,
            expertApproved: null,
            submittedTimestamp: null,
            postponeTimestamp: null,
            implementationApproved: false
        }
    }


}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./edit.html'),
    componentName: "evaluationEdit",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Bewertung Bearbeiten ${ctx.params && ctx.params.id || ''}`;
        return evaluationApi.getEvaluation(ctx.params.id).then((evaluation) => {
            ctx.evaluation = evaluation;
            return ideaApi.getIdea(evaluation.idea.id).then((idea) =>
                ctx.idea = idea
            );
        }).catch(err => console.error(err));
    }
};
